import { Route } from '@angular/router';

export const appRoutes: Route[] = [
    {
        path: 'main',
        loadChildren: () => import('./main/main.routes').then((m)=>m.routes),
      },
      {
        path: '**',
        redirectTo: 'main',
      },
];

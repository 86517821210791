import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UI } from '@rondaver-project/modules/ui';
import { UTILS } from '@rondaver-project/modules/utils';

@Component({
  standalone: true,
  imports: [RouterModule, UTILS,UI],
  selector: 'rondaver-project-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
})
export class AppComponent {
  title = 'rondaver-project';
}

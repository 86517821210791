import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withDebugTracing,
  withHashLocation,
  withViewTransitions,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';

registerLocaleData(es);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withDebugTracing(),
      withHashLocation(),
      withViewTransitions({
        skipInitialTransition: true,
      })
    ),
    { provide: NZ_I18N, useValue: es_ES },
    importProvidersFrom([HttpClientModule,BrowserAnimationsModule]),
  ],
};
